import { useNavigate } from "react-router-dom";
import AnimationWand from "./assets/animation-wand.svg?react";
import Footer from "./components/Footer";
import Header from "./components/Header";

const About: React.FC = () => {

  const navigate = useNavigate();
  
  return (
    <>
      {/* Blog Article */}
      <Header />
      <div className="max-w-3xl px-4 pt-6 lg:pt-10 pb-12 sm:px-6 lg:px-8 mx-auto">
        <div className="max-w-2xl">
          {/* Content */}
          <div className="space-y-5 md:space-y-8">
            <div className="space-y-3">
              <h2 className="text-2xl font-bold md:text-3xl dark:text-white">
                About Worklens
              </h2>
              <p className="text-lg text-gray-800 dark:text-neutral-200">
                Worklens is here to cut down on the hours recruiters spend screening and analyzing candidates. Based out of Stockholm, Sweden, our team has over a decade of experience in hiring and recruiting, and we saw an opportunity to shake things up. Leveraging the latest in AI technology, we’re making it faster and easier to identify top talent and improve the experience for both recruiters and candidates.
              </p>
            </div>
            <p className="text-lg text-gray-800 dark:text-neutral-200">
              Whether you're a recruiter or a job seeker, we're committed to making the hiring process smoother. Instead of slogging through endless interviews and resumes, Worklens allows you to spend time on what really matters – connecting with people. We’ve built custom AI agents that handle the heavy lifting, letting you focus on finding the perfect match.
            </p>
            <figure>
              <img
                className="w-full object-cover rounded-xl max-h-[300px]"
                src="https://images.unsplash.com/photo-1565478441918-ba8d56c559a9?q=80&w=2670&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                alt="Worklens Mission - interviewing the best candidates"
              />
              <figcaption className="mt-3 text-sm text-center text-gray-500 dark:text-neutral-500">
                Worklens is on a mission to spotlight the interview as a key data point in hiring
              </figcaption>
            </figure>
            <p className="text-lg text-gray-800 dark:text-neutral-200">
              Our mission? To make the hiring experience more efficient and enjoyable for everyone involved. By combining our years of industry experience with the power of AI, we’re helping companies and candidates find each other faster – without sacrificing quality or depth in the process.
            </p>
            <blockquote className="text-center p-4 sm:px-7">
              <p className="text-xl font-medium text-gray-800 md:text-2xl md:leading-normal xl:text-2xl xl:leading-normal dark:text-neutral-200">
                "Worklens has saved us countless hours by simplifying candidate screening. We’ve finally got time to focus on what we do best – building our team."
              </p>
              <p className="mt-5 text-gray-800 dark:text-neutral-200">
                - Alex Jonsson, Hiring Manager, Pret a Manger
              </p>
            </blockquote>
            <p className="text-lg text-gray-800 dark:text-neutral-200">
              Ready to see how Worklens can help your hiring process? Join us as we set a new standard for candidate screening.
            </p>
            <button
                onClick={() => navigate("/create-organization")}
                className="relative inline-flex items-center justify-center p-0.5 mb-2 me-2 overflow-hidden text-sm font-medium text-gray-900 rounded-lg group bg-gradient-to-br from-purple-600 to-blue-500 group-hover:from-purple-600 group-hover:to-blue-500 hover:text-white dark:text-white"
              >
                <span className="relative px-5 py-2 transition-all ease-in duration-75 bg-white rounded-md bg-opacity-0 flex items-center">
                  <span className="mr-2 text-lg text-white">Get started</span>
                  <div className="analyze">
                    <AnimationWand />
                  </div>
                </span>
              </button>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
};

export default About;
