import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import emailjs from '@emailjs/browser';

const Footer: React.FC = () => {

  const [contactEmail] = useState(['contact', '@', 'worklens.io'].join(''));


  const [user_email, setUserEmail] = useState('');
  const [status, setStatus] = useState('');

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();

    const serviceID = 'service_8363vmn';
    const templateID = 'template_iktayi2';
    const publicKey = 'kM62g9J0XPB2t-VCY';

    emailjs
      .send(
        serviceID,
        templateID,
        { user_email },
        publicKey
      )
      .then(() => {
        setStatus('Thank you! Our team will get back to you shortly.');
        setUserEmail('');
      })
      .catch(() => {
        setStatus('Failed to send. Please try again.');
      });
  };

  return (
    <footer className="mt-auto bg-gray-900 w-full dark:bg-neutral-950 text-left">
      <div className="mt-auto w-full max-w-[85rem] py-10 px-4 sm:px-6 lg:px-8 lg:pt-20 mx-auto">
        {/* Grid */}
        <div className="grid grid-cols-2 md:grid-cols-4 lg:grid-cols-5 gap-6">
          <div className="col-span-full lg:col-span-1">
            <a
              className="flex-none text-xl font-semibold text-white focus:outline-none focus:opacity-80"
              href="#"
              aria-label="Brand"
            >
              Worklens
            </a>
          </div>
          {/* End Col */}
          <div className="col-span-1">
            <h4 className="font-semibold text-gray-100">Product</h4>
            <div className="mt-3 grid space-y-3">
              <p>
                <a
                  className="inline-flex gap-x-2 text-gray-400 hover:text-gray-200 focus:outline-none focus:text-gray-200 dark:text-neutral-400 dark:hover:text-neutral-200 dark:focus:text-neutral-200"
                  href="#"
                >
                  Pricing
                </a>
              </p>
              <p>
                <Link
                  to="/agreement-organization" className="inline-flex gap-x-2 text-gray-400 hover:text-gray-200 focus:outline-none focus:text-gray-200 dark:text-neutral-400 dark:hover:text-neutral-200 dark:focus:text-neutral-200"
                  onClick={() => window.scrollTo(0, 0)}>
                  Privacy
                </Link>
              </p>
            </div>
          </div>
          {/* End Col */}
          <div className="col-span-1">
            <h4 className="font-semibold text-gray-100">Company</h4>
            <div className="mt-3 grid space-y-3">
              <p>
                <Link
                  to="/about" className="inline-flex gap-x-2 text-gray-400 hover:text-gray-200 focus:outline-none focus:text-gray-200 dark:text-neutral-400 dark:hover:text-neutral-200 dark:focus:text-neutral-200"
                  onClick={() => window.scrollTo(0, 0)}>
                  About
                </Link>
              </p>
              <p>
                <a
                  className="inline-flex gap-x-2 text-gray-400 hover:text-gray-200 focus:outline-none focus:text-gray-200 dark:text-neutral-400 dark:hover:text-neutral-200 dark:focus:text-neutral-200"
                  href="#">
                  Blog
                </a>
              </p>
              <p>
                <a
                  className="inline-flex gap-x-2 text-gray-400 hover:text-gray-200 focus:outline-none focus:text-gray-200 dark:text-neutral-400 dark:hover:text-neutral-200 dark:focus:text-neutral-200"
                  href={`mailto:${contactEmail}`}>
                  Contact
                </a>
              </p>
            </div>
          </div>
          {/* End Col */}
          <div className="col-span-2">
            <h4 className="font-semibold text-gray-100">Book a demo</h4>
            <form onSubmit={handleSubmit}>
              <div className="mt-4 flex flex-col items-center gap-2 sm:flex-row sm:gap-3 bg-white rounded-lg p-2 dark:bg-neutral-900">
                <div className="w-full">
                  <label htmlFor="hero-input" className="sr-only">
                    Subscribe
                  </label>
                  <input
                    type="text"
                    id="hero-input"
                    name="hero-input"
                    value={user_email}
                    onChange={(e) => setUserEmail(e.target.value)}
                    className="py-3 px-4 block w-full border-transparent rounded-lg text-sm focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-900 dark:border-transparent dark:text-neutral-400 dark:placeholder-neutral-500 dark:focus:ring-neutral-600 text-gray-800 dark:text-neutral-200"
                    placeholder="Enter your email"
                  />
                </div>
                <button
                  type="submit"
                  className="py-3 px-4 inline-flex justify-center items-center gap-x-2 text-sm font-medium rounded-lg border border-transparent bg-blue-600 text-white hover:bg-blue-700 focus:outline-none focus:bg-blue-700 disabled:opacity-50 disabled:pointer-events-none"
                >
                  Submit
                </button>
              </div>
              <p className="mt-3 text-sm text-gray-400">
                We'll throw in 2 free months to get you started.
              </p>
              {status && (
                <div className="mt-3 text-sm text-gray-400 dark:text-neutral-200">
                  {status}
                </div>
              )}
            </form>
          </div>
          {/* End Col */}
        </div>
        {/* End Grid */}
        <div className="mt-5 sm:mt-12 grid gap-y-2 sm:gap-y-0 sm:flex sm:justify-between sm:items-center">
          <div className="flex justify-between items-center">
            <p className="text-sm text-gray-400 dark:text-neutral-400">
              © 2024 Worklens. All rights reserved.
            </p>
          </div>
          {/* End Col */}
        </div>
      </div>
    </footer>
  );
}

export default Footer;