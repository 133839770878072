import React, { useCallback, useEffect, useState } from 'react';
import ReactMarkdown, { Components } from 'react-markdown';
import AnimationWand from "../../assets/animation-wand.svg?react";
import remarkGfm from 'remark-gfm';
import { Node } from 'unist';
import Settings from '../../Settings';
import { ArrowLeft, ArrowRight, CalendarDays, CirclePlus, CircleX } from 'lucide-react';
import { DragDropContext, Droppable, Draggable } from '@hello-pangea/dnd';
import { useAuth0 } from '@auth0/auth0-react';
import { toast } from 'react-toastify';
import { InterviewSettingsLLM, InterviewState, OrganizationInterviewGet, Question } from '../../types';
import PreviewInterview from './PreviewInterview';
import { useParams } from 'react-router-dom';
import { FormSteps } from './CreateInterview';


type CheckboxState = Record<number, boolean>;

interface InputElementQuestion {
  id: number;
  text: string;
  order: number;
}

const UpdateInterview: React.FC = () => {
  const { getAccessTokenSilently } = useAuth0();
  const { interviewId } = useParams();

  const [isLoadingSuggestions, setIsLoadingSuggestions] = useState<boolean>(false);
  const [title, setTitle] = useState<string>('');
  const [employerName, setEmployerName] = useState<string>('');
  const [jobAd, setJobAd] = useState<string>('');
  const [expireAt, setExpireAt] = useState<string>('');
  const [responseText, setResponseText] = useState<string>('');
  const [checkedItems, setCheckedItems] = useState<CheckboxState>({});
  const [questions, setQuestions] = useState<InputElementQuestion[]>([]);
  const [stateType, setStateType] = useState<InterviewState[]>([]);

  const [interview, setInterview] = useState<OrganizationInterviewGet>();
  const [formStep, setFormStep] = useState<FormSteps>(FormSteps.Details);
  const [settingsLLM, setSettingsLLM] = useState<InterviewSettingsLLM>();
  const [created, setCreated] = useState<string>('');

  useEffect(() => {
    const getInterview = async () => {
      const token = await getAccessTokenSilently();

      try {
        const response = await fetch(
          `${Settings.API_URL}/organizations/interviews?&id=${interviewId}`,
          {
            method: 'GET',
            headers: {
              'Authorization': `Bearer ${token}`
            },
          }
        );

        if (response.ok) {
          const interviewResponse = await response.json();

          setTitle(interviewResponse.title);
          setEmployerName(interviewResponse.employerName);
          setJobAd(interviewResponse.jobAd);
          if (interviewResponse.expireAt && interviewResponse.expireAt.split('T').length)
            setExpireAt(interviewResponse.expireAt.split('T')[0]);

          const fetchedQuestions = interviewResponse.questions.map((question: Question, index: number) => ({
            ...question,
            order: index,
          }));

          setQuestions(fetchedQuestions);
          setCreated(interviewResponse.created);
          setSettingsLLM(interviewResponse.settingsLLM);
          setStateType(interviewResponse.state);
        }
      } catch (error) {
        console.error('Error fetching interview:', error);
      }
    };

    getInterview();
  }, []);

  const getSuggestions = async () => {
    if (jobAd === '') {
      toast("You'll need to add a job ad for suggestions");
      return;
    }

    setIsLoadingSuggestions(true);
    setResponseText('');

    try {
      const body = JSON.stringify({
        promptId: 0,
        text: jobAd,
      });

      const response = await fetch(`${Settings.API_URL}/organizations/interviews/suggest`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: body,
      });

      if (!response.ok || !response.body) {
        throw new Error('Network response was not ok');
      }

      const reader = response.body.getReader();
      const decoder = new TextDecoder('utf-8');

      let fullText = '';
      while (true) {
        const { value, done } = await reader.read();
        if (done) break;
        const chunk = decoder.decode(value, { stream: true });
        fullText += chunk;
        setResponseText((prev) => prev + chunk);
      }
    } catch (error) {
      console.error('Error:', error);
      setResponseText('An error occurred');
    } finally {
      setIsLoadingSuggestions(false);
    }
  };

  const previewInterview = async () => {
    if (!settingsLLM)
      return

    const organizationInterview: OrganizationInterviewGet = {
      id: Number(interviewId),
      title: title,
      jobAd: jobAd,
      questions: questions.map((question) => ({
        id: question.order,
        text: question.text,
      })),
      employerName: employerName,
      expireAt: expireAt,
      created: created,
      state: stateType,
      settingsLLM: settingsLLM
    };

    setInterview(organizationInterview);
    setFormStep(FormSteps.Preview);
  }

  const extractMarkdown = useCallback((text: string) => {
    const codeBlockRegex = /```markdown\n([\s\S]*?)```/;
    const match = text.match(codeBlockRegex);
    if (match && match[1]) {
      return match[1].trim();
    }

    return text;
  }, []);

  const handleCheckboxChange = (index: number, text: string) => {
    setCheckedItems((prev) => ({
      ...prev,
      [index]: !prev[index],
    }));

    if (!checkedItems[index]) {
      setQuestions((prev) => [
        ...prev,
        { id: index, text: text, order: prev.length },
      ]);
    } else {
      setQuestions((prev) => prev.filter((input) => input.id !== index));
    }
  };

  const removeInputField = (id: number) => {
    setQuestions((prevFields) =>
      prevFields
        .filter((field) => field.id !== id)
        .map((field, index) => ({ ...field, order: index }))
    );
  };

  const handleInputChange = (id: number, value: string) => {
    setQuestions((prev) =>
      prev.map((field) => (field.id === id ? { ...field, text: value } : field))
    );
  };

  const addStaticQuestion = () => {
    const newQuestion = {
      id: Date.now(),
      text: '',
      order: questions.length
    };
    setQuestions((prevQuestions) => [...prevQuestions, newQuestion]);
  };

  const onDragEnd = (result: any) => {
    if (!result.destination) return;

    const reorderedQuestions = Array.from(questions);
    const [movedItem] = reorderedQuestions.splice(result.source.index, 1);
    reorderedQuestions.splice(result.destination.index, 0, movedItem);

    // Update `order` property based on new index positions
    const updatedQuestions = reorderedQuestions.map((question, index) => ({
      ...question,
      order: index,
    }));

    setQuestions(updatedQuestions);
  };

  const checkboxRenderer: Components = {
    li: ({ children, node, ...props }) => {
      const isTaskItem = node?.children.some(
        (child: Node) => (child.type as string) === 'element' && (child as any).tagName === 'input'
      );

      const index = node?.position?.start.line || 0;
      const textContent = React.Children.toArray(children)
        .filter((child) => typeof child === 'string')
        .join('');

      return (
        <li className="task-list-item" {...props}>
          {isTaskItem && (
            <input
              type="checkbox"
              checked={checkedItems[index] || false}
              onChange={() => handleCheckboxChange(index, textContent)}
              style={{ marginRight: '8px' }}
            />
          )}
          {React.Children.toArray(children).filter(
            (child) => !(React.isValidElement(child) && child.type === 'input')
          )}
        </li>
      );
    },
  };

  return (
    <div className="flex flex-col">
      <div className="p-1 grid sm:grid-cols-3 gap-2 mb-8">
        <div className="flex items-center max-w-[50%]">
          <div className="flex-shrink-0 mr-2">
            <div className="flex items-center whitespace-nowrap">
              <span className={`${formStep === FormSteps.Details ? 'bg-[#2563eb]' : 'bg-gray-700'} w-8 h-8 rounded-full flex justify-center items-center text-sm text-white`}>1</span>
            </div>
          </div>
          <div>
            <h3 className="font-semibold text-gray-800 dark:text-white">
              Details
            </h3>
          </div>
        </div>

        <div className="flex items-center">
          <div className="flex-shrink-0 mr-2">
            <div className="flex items-center whitespace-nowrap">
              <span className={`${formStep === FormSteps.Questions ? 'bg-[#2563eb]' : 'bg-gray-700'} w-8 h-8 rounded-full flex justify-center items-center text-sm text-white`}>2</span>
            </div>
          </div>
          <div>
            <h3 className="font-semibold text-gray-800 dark:text-white">
              Questions
            </h3>
          </div>
        </div>

        <div className="flex items-center">
          <div className="flex-shrink-0 mr-2">
            <div className="flex items-center whitespace-nowrap">
              <span className={`${formStep === FormSteps.Preview ? 'bg-[#2563eb]' : 'bg-gray-700'} w-8 h-8 rounded-full flex justify-center items-center text-sm text-white`}>3</span>
            </div>
          </div>
          <div>
            <h3 className="font-semibold text-gray-800 dark:text-white">
              Preview
            </h3>
          </div>
        </div>
      </div>

      <div className="grid lg:grid-cols-2 gap-4 sm:gap-6">
        {formStep === FormSteps.Details && (
          <div className="px-6 py-4 min-h-[410px] flex flex-col bg-white border shadow-sm rounded-xl dark:bg-neutral-800 dark:border-neutral-700">
            <div className="mb-4 sm:mb-8">
              <label
                htmlFor="hs-feedback-post-comment-name-1"
                className="block mb-2 text-sm font-medium text-gray-500 dark:text-neutral-400"
              >
                Employer
              </label>
              <input
                type="text"
                id="hs-feedback-post-comment-name-1"
                className="bg-gray-100 py-3 px-4 block w-full border-gray-200 rounded-lg text-sm focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-900 dark:border-neutral-700 dark:text-neutral-400 dark:placeholder-neutral-500 dark:focus:ring-neutral-600 text-black"
                placeholder="Employer name (e.g Google)"
                value={employerName}
                disabled={true}
              />
            </div>
            <div className="mb-4 sm:mb-8">
              <label
                htmlFor="hs-feedback-post-comment-name-1"
                className="block mb-2 text-sm font-medium text-gray-500 dark:text-neutral-400"
              >
                Interview title
              </label>
              <input
                type="text"
                id="hs-feedback-post-comment-name-1"
                className="bg-gray-100 py-3 px-4 block w-full border-gray-200 rounded-lg text-sm focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-900 dark:border-neutral-700 dark:text-neutral-400 dark:placeholder-neutral-500 dark:focus:ring-neutral-600 text-black"
                placeholder="Interview title"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
              />
            </div>
            <div className="mb-4 sm:mb-8">
              <label
                className="block mb-2 text-sm font-medium text-gray-500 dark:text-neutral-400"
              >
                Job ad
              </label>
              <div className="mt-1">
                <textarea
                  value={jobAd}
                  onChange={(e) => setJobAd(e.target.value)}
                  className="bg-gray-100 py-3 px-4 block w-full border-gray-200 rounded-lg text-sm focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-900 dark:border-neutral-700 dark:text-neutral-400 dark:placeholder-neutral-500 dark:focus:ring-neutral-600 text-black"
                  placeholder="Enter your full job ad here..."
                  rows={5}
                ></textarea>
              </div>
            </div>

            <div className="mb-6">
              <label
                htmlFor="hs-feedback-post-comment-textarea-1"
                className="block mb-2 text-sm font-medium text-gray-500 dark:text-neutral-400"
              >
                Expiration date
              </label>
              <div className="mt-1 relative">
                <input
                  type="date"
                  value={expireAt}
                  onChange={(e) => setExpireAt(e.target.value)}
                  className="bg-gray-100 py-3 px-4 block w-full border-gray-200 rounded-lg text-sm focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-900 dark:border-neutral-700 dark:text-neutral-400 dark:placeholder-neutral-500 dark:focus:ring-neutral-600 text-black"
                />
                <div className="absolute inset-y-0 right-0 flex items-center pr-3 pointer-events-none">
                  <CalendarDays className="h-5 w-5 text-black dark:text-white" />
                </div>
              </div>
            </div>
            <div className="mt-6 flex gap-4 items-end justify-between">
              <button
                onClick={() => setFormStep(FormSteps.Questions)}
                type="button"
                disabled={(employerName === '' || title === '' || jobAd === '')}
                className="h-12 w-1/4 px-4 inline-flex items-center justify-center gap-x-2 text-sm font-medium rounded-lg border border-gray-200 bg-white text-gray-800 shadow-sm hover:bg-gray-50 focus:outline-none focus:bg-gray-50 disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-800 dark:border-blue-600 dark:text-white dark:hover:bg-blue-600 ml-auto"
              >
                Next
                <ArrowRight className="w-4 h-4" />
              </button>
            </div>
          </div>
        )}

        {formStep === FormSteps.Questions && (
          <>
            <div className="px-6 py-4 min-h-[410px] flex flex-col bg-white border shadow-sm rounded-xl dark:bg-neutral-800 dark:border-neutral-700">
              <span className="block mb-6 text-sm font-medium text-gray-800 dark:text-white">
                Interview questions
              </span>
              <div className="mb-4 flex gap-4 items-center">
                <button
                  type="button"
                  onClick={getSuggestions}
                  disabled={isLoadingSuggestions}
                  className="py-2 px-4 inline-flex items-center gap-x-2 text-sm font-medium rounded-lg border border-transparent bg-blue-600 text-white hover:bg-blue-700 focus:outline-none focus:bg-blue-700 disabled:opacity-50 disabled:pointer-events-none">
                  <span className="mr-2">{isLoadingSuggestions ? 'Getting suggestions...' : 'Get question suggestions'}</span>
                  <div className="analyze">
                    <AnimationWand className="w-7 h-7" />
                  </div>
                </button>
                <button
                  onClick={addStaticQuestion}
                  type="button"
                  className="ml-auto py-3 px-4 inline-flex items-center gap-x-2 text-sm font-medium rounded-lg border border-gray-200 bg-white text-gray-800 shadow-sm hover:bg-gray-50 focus:outline-none focus:bg-gray-50 disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-800 dark:border-neutral-700 dark:text-white dark:hover:bg-neutral-700 dark:focus:bg-neutral-700"
                >
                  New question
                  <CirclePlus className="w-4 h-4" />
                </button>
              </div>
              <div className="mb-6">
                <span className="block mb-2 text-sm font-medium text-white">
                  Interview questions
                </span>
                <div className="mt-1 space-y-4">
                  <DragDropContext onDragEnd={onDragEnd}>
                    <Droppable droppableId="droppable-questions">
                      {(provided) => (
                        <div
                          {...provided.droppableProps}
                          ref={provided.innerRef}
                          className="flex flex-col"
                        >
                          {questions
                            .sort((a, b) => a.order - b.order) // Sort questions by their order property
                            .map((field, index) => (
                              <Draggable key={field.id} draggableId={`${field.id}`} index={index}>
                                {(provided) => (
                                  <div
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                    className="w-full inline-flex items-center gap-x-1 py-3 px-4 text-sm font-medium bg-transparent border border-gray-200 text-gray-800 -mt-px first:rounded-t-lg first:mt-0 last:rounded-b-lg dark:text-white space-x-2 p-2 bg-transparent"
                                  >
                                    <textarea
                                      rows={3}
                                      className="bg-gray-100 py-3 px-4 block w-full border-gray-200 rounded-lg text-sm focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-900 dark:border-neutral-700 dark:text-neutral-400 dark:placeholder-neutral-500 dark:focus:ring-neutral-600 text-black"
                                      value={field.text}
                                      onChange={(e) => handleInputChange(field.id, e.target.value)}
                                    />
                                    <a
                                      onClick={() => removeInputField(field.id)}
                                      className="cursor-pointer hover:text-red-400"
                                    >
                                      <CircleX className="w-4 h-4" />
                                    </a>
                                    <i className="grip"></i>
                                  </div>
                                )}
                              </Draggable>
                            ))}
                          {provided.placeholder}
                        </div>
                      )}
                    </Droppable>
                  </DragDropContext>
                </div>
              </div>

              <div className="mt-8 flex gap-4 items-center justify-between">
                <button
                  onClick={() => setFormStep(FormSteps.Details)}
                  type="button"
                  className="h-12 py-2 px-4 w-1/4 flex items-center justify-center gap-x-2 text-sm font-medium rounded-lg border border-gray-200 bg-white text-gray-800 shadow-sm hover:bg-gray-50 focus:outline-none focus:bg-gray-50 disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-800 dark:border-blue-600 dark:text-white dark:hover:bg-blue-600"
                >
                  <ArrowLeft className="w-4 h-4 shrink-0" />
                  <span>Details</span>
                </button>
                <button
                  onClick={() => previewInterview()}
                  disabled={!questions.length}
                  type="button"
                  className="h-12 w-2/5 px-4 inline-flex items-center justify-center gap-x-2 text-sm font-medium rounded-lg border border-gray-200 bg-white text-gray-800 shadow-sm hover:bg-gray-50 focus:outline-none focus:bg-gray-50 disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-800 dark:border-blue-600 dark:text-white dark:hover:bg-blue-600 ml-auto"
                >
                  Preview interview
                  <ArrowRight className="w-4 h-4" />
                </button>
              </div>


            </div>
            <div className="p-4 md:p-5 min-h-[410px] flex flex-col bg-white border shadow-sm rounded-xl dark:bg-neutral-800 dark:border-neutral-700">
              <div className="flex justify-between items-center mb-4">
                <h2 className="text-xl font-semibold text-gray-800 dark:text-neutral-200">
                  {isLoadingSuggestions ? 'Loading suggestions...' : 'Your question suggestions show up here'}
                </h2>
              </div>
              <div className="p-4 text-sm">
                <ReactMarkdown
                  className="prose dark:prose-invert"
                  remarkPlugins={[remarkGfm]}
                  components={checkboxRenderer}>
                  {extractMarkdown(responseText)}
                </ReactMarkdown>
              </div>
            </div>
          </>
        )}

        {formStep === FormSteps.Preview && interview && (
          <PreviewInterview interview={interview} setFormStep={setFormStep} />
        )}
      </div>
    </div>
  );
};

export default UpdateInterview;
