import { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useAuth } from "./AuthContext";
import Settings from "./Settings";
import Header from "./components/Header";
import Footer from "./components/Footer";
import { logFatalSentryError } from "./util";

const LoginCallbackCandidate: React.FC = () => {

  const { loginCandidate, isAuthenticatedCandidate } = useAuth();
  const location = useLocation();
  const navigate = useNavigate();
  const [isLoggingIn, setIsLoggingIn] = useState(false);
  const hasExchangedToken = useRef(false);

  useEffect(() => {
    const urlParams = new URLSearchParams(location.search);
    const code = urlParams.get('code');
    const state = urlParams.get('state');
    const scope = (state && JSON.parse(state)?.scope) ?? null;
    const redirectURL = (state && JSON.parse(state)?.redirectURL) ?? null;

    if (code && scope && !isAuthenticatedCandidate && !hasExchangedToken.current) {
      hasExchangedToken.current = true;
      exchangeCodeForToken(code, scope, redirectURL);
    }
  }, [location, isAuthenticatedCandidate]);

  const exchangeCodeForToken = async (code: string, scope: string, redirectURL?: string) => {
    setIsLoggingIn(true);

    try {
      const response = await fetch(`${Settings.API_URL}/candidates/redirect`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ code, scope }),
        credentials: 'include'
      });

      if (!response.ok) {
        throw new Error('Failed to exchange code for token');
      }

      const candidate = await response.json();

      await loginCandidate(candidate);
    } catch (error) {
      logFatalSentryError("Authentication error", error);
      console.error('Authentication error:', error);
    } finally {
      if (redirectURL) {
        window.location.href = redirectURL;
      } else {
        navigate(`/profile-candidate`)
      }

      setIsLoggingIn(false);
    }
  };

  return (
    <main className="flex-grow">

      <Header />

      <div className="relative overflow-hidden text-left">
        <div className="max-w-[40rem] mx-auto px-4 sm:px-6 lg:px-8 py-4 sm:py-24">
          <div className="mt-8">
            {isLoggingIn && (
              <div className="text-center fixed flex items-center justify-center z-50">
                <div className="animate-spin inline-block size-6 border-[3px] border-current border-t-transparent text-blue-600 rounded-full dark:text-blue-500" role="status" aria-label="loading"></div>
                &nbsp;<p>Logging in..</p>
              </div>
            )}
          </div>
        </div>
      </div>

      <Footer />

    </main>
  );
};

export default LoginCallbackCandidate;