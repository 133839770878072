import Footer from "./components/Footer";
import Header from "./components/Header";

const AgreementOrganization: React.FC = () => {

  return (
    <main className="flex-grow">

      <Header />

      <div className="relative overflow-hidden">
        <div className="max-w-[85rem] min-h-[40rem] mx-auto px-4 sm:px-6 lg:px-8 py-4 sm:py-24 text-left">
          <div className="text-white">

            <div className="space-y-4 text-gray-800 dark:text-white">

              <div className="max-w-4xl mx-auto py-12 px-6">
                <h1 className="text-3xl font-bold mb-6">Worklens</h1>
                <h2 className="text-2xl font-semibold mb-4">User Agreement for Organizations</h2>
                <p className="mb-8">
                  Effective Date: <span className="font-medium">2024-10-31</span>
                </p>
                <hr className="mb-8" />
                <div className="space-y-8">
                  {/* 1. Introduction */}
                  <div>
                    <h3 className="text-xl font-semibold mb-4">1. Introduction</h3>
                    <p>
                      This User Agreement (<strong>"Agreement"</strong>) is a legal contract
                      between <strong>Worklens</strong> (
                      <strong>"we," "us,"</strong> or <strong>"our"</strong>) and the entity
                      agreeing to these terms (<strong>"you"</strong> or{" "}
                      <strong>"your"</strong>). By accessing or using our services, you
                      agree to be bound by this Agreement.
                    </p>
                  </div>
                  {/* 2. Services */}
                  <div>
                    <h3 className="text-xl font-semibold mb-4">2. Services</h3>
                    <p>
                      We provide AI-powered agents for conducting job applicant interviews
                      and post-screening analysis (the <strong>"Services"</strong>). The
                      Services are intended for business-to-business use.
                    </p>
                  </div>
                  {/* 3. Fees and Payment */}
                  <div>
                    <h3 className="text-xl font-semibold mb-4">3. Fees and Payment</h3>
                    <ul className="list-disc pl-5 space-y-2">
                      <li>
                        <strong>Pricing</strong>: Fees for the Services are outlined in the
                        Order Form or Pricing Schedule.
                      </li>
                      <li>
                        <strong>Price Changes</strong>: We reserve the right to modify our
                        fees. Any price changes will be communicated to you via email at
                        least 30 days in advance.
                      </li>
                      <li>
                        <strong>Payment Terms</strong>: Payments are due as specified in the
                        invoice or agreed payment schedule.
                      </li>
                    </ul>
                  </div>
                  {/* 4. Confidentiality */}
                  <div>
                    <h3 className="text-xl font-semibold mb-4">4. Confidentiality</h3>
                    <p>
                      Both parties agree to maintain the confidentiality of any proprietary
                      or sensitive information disclosed during the term of this Agreement.
                    </p>
                  </div>
                  {/* 5. Data Protection and Privacy */}
                  <div>
                    <h3 className="text-xl font-semibold mb-4">
                      5. Data Protection and Privacy
                    </h3>
                    <ul className="list-disc pl-5 space-y-2">
                      <li>
                        <strong>GDPR Compliance</strong>: We store all customer data within
                        the European Union and comply with the General Data Protection
                        Regulation (GDPR).
                      </li>
                      <li>
                        <strong>Data Security</strong>: We implement appropriate technical
                        and organizational measures to protect personal data against
                        unauthorized access, alteration, or disclosure.
                      </li>
                    </ul>
                  </div>
                  {/* 6. Intellectual Property Rights */}
                  <div>
                    <h3 className="text-xl font-semibold mb-4">
                      6. Intellectual Property Rights
                    </h3>
                    <ul className="list-disc pl-5 space-y-2">
                      <li>
                        <strong>Ownership of Data</strong>: You retain all rights to your
                        data. We do not claim ownership over any content or data you provide
                        while using the Services.
                      </li>
                      <li>
                        <strong>AI Outputs</strong>: Outputs generated by our AI agents are
                        considered your property.
                      </li>
                    </ul>
                  </div>
                  {/* 7. Acceptable Use Policy */}
                  <div>
                    <h3 className="text-xl font-semibold mb-4">7. Acceptable Use Policy</h3>
                    <ul className="list-disc pl-5 space-y-2">
                      <li>
                        <strong>Prohibited Activities</strong>: You agree not to misuse the
                        Services, including but not limited to violating any laws,
                        infringing on intellectual property rights, or engaging in
                        fraudulent activities.
                      </li>
                      <li>
                        <strong>User Responsibilities</strong>: You are responsible for
                        maintaining the confidentiality of your account credentials and for
                        all activities that occur under your account.
                      </li>
                    </ul>
                  </div>
                  {/* 8. Limitation of Liability */}
                  <div>
                    <h3 className="text-xl font-semibold mb-4">
                      8. Limitation of Liability
                    </h3>
                    <p>
                      To the maximum extent permitted by law, our total liability for any
                      claims under this Agreement is limited to the amount you paid for the
                      Services in the 12 months preceding the incident.
                    </p>
                  </div>
                  {/* 9. Warranty Disclaimer */}
                  <div>
                    <h3 className="text-xl font-semibold mb-4">9. Warranty Disclaimer</h3>
                    <p>
                      The Services are provided <strong>"as is"</strong> without warranties
                      of any kind, either express or implied. We do not guarantee that the
                      Services will be error-free or uninterrupted.
                    </p>
                  </div>
                  {/* 10. Indemnification */}
                  <div>
                    <h3 className="text-xl font-semibold mb-4">10. Indemnification</h3>
                    <p>
                      You agree to indemnify and hold us harmless from any claims, losses,
                      or damages arising out of your use of the Services or violation of
                      this Agreement.
                    </p>
                  </div>
                  {/* 11. Termination */}
                  <div>
                    <h3 className="text-xl font-semibold mb-4">11. Termination</h3>
                    <ul className="list-disc pl-5 space-y-2">
                      <li>
                        <strong>Termination by You</strong>: You may terminate this
                        Agreement at any time by providing written notice.
                      </li>
                      <li>
                        <strong>Termination by Us</strong>: We may suspend or terminate your
                        access to the Services if you violate any terms of this Agreement.
                      </li>
                      <li>
                        <strong>Effect of Termination</strong>: Upon termination, your
                        access to the Services will cease. You remain responsible for any
                        outstanding fees.
                      </li>
                    </ul>
                  </div>
                  {/* 12. Amendments to the Agreement */}
                  <div>
                    <h3 className="text-xl font-semibold mb-4">
                      12. Amendments to the Agreement
                    </h3>
                    <p>
                      We may update this Agreement from time to time. Any changes will be
                      communicated to you via email. You must accept the new terms before
                      continuing to use the Services. Failure to accept the new terms may
                      result in suspension of your account, during which fees will continue
                      to accrue.
                    </p>
                  </div>
                  {/* 13. Governing Law and Jurisdiction */}
                  <div>
                    <h3 className="text-xl font-semibold mb-4">
                      13. Governing Law and Jurisdiction
                    </h3>
                    <p>
                      This Agreement is governed by the laws of
                      <strong>the EU</strong>. Any disputes arising out of
                      this Agreement shall be subject to the exclusive jurisdiction of the
                      courts located in <strong>the EU</strong>.
                    </p>
                  </div>
                  {/* 14. Force Majeure */}
                  <div>
                    <h3 className="text-xl font-semibold mb-4">14. Force Majeure</h3>
                    <p>
                      We shall not be liable for any failure to perform due to causes beyond
                      our reasonable control, such as natural disasters, acts of war, or
                      government regulations.
                    </p>
                  </div>
                  {/* 15. Notices */}
                  <div>
                    <h3 className="text-xl font-semibold mb-4">15. Notices</h3>
                    <p>
                      All notices and communications shall be sent to the email addresses
                      provided by both parties in writing.
                    </p>
                  </div>
                  {/* 16. Assignment */}
                  <div>
                    <h3 className="text-xl font-semibold mb-4">16. Assignment</h3>
                    <p>
                      Neither party may assign or transfer any rights or obligations under
                      this Agreement without the prior written consent of the other party.
                    </p>
                  </div>
                  {/* 17. Third-Party Services */}
                  <div>
                    <h3 className="text-xl font-semibold mb-4">17. Third-Party Services</h3>
                    <p>
                      Our Services may include integrations with third-party services. We
                      are not responsible for the performance or terms of these third-party
                      services.
                    </p>
                  </div>
                  {/* 18. Ethical Use of AI */}
                  <div>
                    <h3 className="text-xl font-semibold mb-4">18. Ethical Use of AI</h3>
                    <ul className="list-disc pl-5 space-y-2">
                      <li>
                        <strong>Bias and Discrimination</strong>: We are committed to
                        minimizing bias in our AI algorithms and ensuring fair treatment of
                        all job applicants.
                      </li>
                      <li>
                        <strong>Transparency</strong>: We will provide information about how
                        our AI agents make decisions upon request.
                      </li>
                    </ul>
                  </div>
                  {/* 19. Data Retention and Deletion */}
                  <div>
                    <h3 className="text-xl font-semibold mb-4">
                      19. Data Retention and Deletion
                    </h3>
                    <p>
                      We retain data as necessary to provide the Services. You may request
                      deletion of your data in accordance with GDPR guidelines.
                    </p>
                  </div>
                  {/* 20. Compliance with Laws */}
                  <div>
                    <h3 className="text-xl font-semibold mb-4">20. Compliance with Laws</h3>
                    <p>
                      You agree to use the Services in compliance with all applicable laws
                      and regulations, including employment and anti-discrimination laws.
                    </p>
                  </div>
                  {/* 21. Entire Agreement */}
                  <div>
                    <h3 className="text-xl font-semibold mb-4">21. Entire Agreement</h3>
                    <p>
                      This Agreement constitutes the entire understanding between the
                      parties and supersedes all prior agreements, representations, or
                      understandings.
                    </p>
                  </div>
                  {/* 22. Severability */}
                  <div>
                    <h3 className="text-xl font-semibold mb-4">22. Severability</h3>
                    <p>
                      If any provision of this Agreement is found to be unenforceable or
                      invalid, the remaining provisions shall remain in full force and
                      effect.
                    </p>
                  </div>
                  {/* 23. Contact Information */}
                  <div>
                    <h3 className="text-xl font-semibold mb-4">23. Contact Information</h3>
                    <p>
                      For any questions or concerns regarding this Agreement, please contact
                      us at:
                    </p>
                    <p className="mt-4">
                      <strong>Worklens</strong>
                      <br />
                      Email:&nbsp;
                      <a
                        href="mailto:contact@worklens.io"
                        className="text-blue-600 hover:underline"
                      >
                        contact@worklens.io
                      </a>
                      <br />
                    </p>
                  </div>
                </div>
                <hr className="my-8" />
                <p className="mt-8">
                  <strong>
                    By using our Services, you acknowledge that you have read, understood,
                    and agree to be bound by this Agreement.
                  </strong>
                </p>
              </div>

            </div>
          </div>
        </div>
      </div>

      <Footer />

    </main>
  );
};

export default AgreementOrganization;
