import React, { useState, useEffect, useRef } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import LinkedInLogo from "../../assets/linkedin-logo.svg?react";
import Settings from '../../Settings';
import { OrganizationRatingGet, OrganizationSummaryGet } from '../../types';
import { ArrowDown, ArrowUp, Info, Search, Star } from 'lucide-react';
import { Tooltip } from 'react-tooltip'
import { NavLink, useParams } from 'react-router-dom';
import { capitalizeFirstWord } from '../../util';


type BookmarksMap = {
  [key: string]: {
    created: string;
    externalUserId: string;
  } | null;
};

type QueryAnswer = {
  answer: string;
}

const ViewApplications: React.FC = () => {
  
  const queryRef = useRef<HTMLInputElement>(null);

  const { interviewId } = useParams();
  const [isLoadingSummaries, setIsLoadingSummaries] = useState<boolean>(false);
  const [summaries, setSummaries] = useState<OrganizationSummaryGet[]>([]);
  const [ratings, setRatings] = useState<{ [key: string]: { rating: number; rationale: string } }>({});
  const [bookmarksMap, setBookmarksMap] = useState<BookmarksMap>({});
  const [isLoadingQueryAnswer, setIsLoadingQueryAnswer] = useState<boolean>(false);
  const [queryAnswer, setQueryAnswer] = useState<QueryAnswer | null>();

  const { getAccessTokenSilently } = useAuth0();

  const [sortConfig, setSortConfig] = useState({ key: 'rating', direction: 'descending' });
  const [candidateFilter, setCandidateFilter] = useState<string>('');

  const requestSort = (key: string) => {
    let direction = 'ascending';
    if (sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }
    setSortConfig({ key, direction });
  };

  const filteredSummaries = React.useMemo(() => {
    if (candidateFilter.length > 2) {
      return summaries.filter((summary) =>
        summary.candidateName.toLowerCase().includes(candidateFilter.toLowerCase())
      );
    }
    return summaries;
  }, [summaries, candidateFilter]);

  const sortedSummaries = React.useMemo(() => {
    let sortableSummaries = [...filteredSummaries];
    if (sortConfig !== null) {
      sortableSummaries.sort((a, b) => {
        let aRating = ratings[a.applicationUuid] ? ratings[a.applicationUuid].rating : 0;
        let bRating = ratings[b.applicationUuid] ? ratings[b.applicationUuid].rating : 0;

        if (aRating < bRating) {
          return sortConfig.direction === 'ascending' ? -1 : 1;
        }
        if (aRating > bRating) {
          return sortConfig.direction === 'ascending' ? 1 : -1;
        }
        return 0;
      });
    }
    return sortableSummaries;
  }, [filteredSummaries, ratings, sortConfig]);

  useEffect(() => {
    const getSummaries = async () => {
      setIsLoadingSummaries(true);
      try {
        const token = await getAccessTokenSilently();
  
        // Fetch summaries
        const responseSummaries = await fetch(
          `${Settings.API_URL}/organizations/summaries?interviewId=${interviewId}`,
          {
            method: 'GET',
            headers: { Authorization: `Bearer ${token}` },
          }
        );
        const summaryResults: OrganizationSummaryGet[] = await responseSummaries.json();
        console.log("Fetched summaries:", summaryResults);
  
        // Deduplicate summaries by applicationUuid
        const summariesByUuid = summaryResults.reduce((acc, current) => {
          const uuid = current.applicationUuid;
  
          if (!acc[uuid]) {
            acc[uuid] = { ...current };
          } else {
            // Merge and deduplicate arrays using Set
            acc[uuid].keySkills = Array.from(
              new Set([...acc[uuid].keySkills, ...current.keySkills])
            );
            acc[uuid].strengths = Array.from(
              new Set([...acc[uuid].strengths, ...current.strengths])
            );
          }
          return acc;
        }, {} as Record<string, OrganizationSummaryGet>);
  
        const uniqueSummaries = Object.values(summariesByUuid);
        if (uniqueSummaries.length > 0) {
          setSummaries(uniqueSummaries);
        }
  
        // Fetch ratings
        const responseRatings = await fetch(
          `${Settings.API_URL}/organizations/ratings?interviewId=${interviewId}`,
          {
            method: 'GET',
            headers: { Authorization: `Bearer ${token}` },
          }
        );
        const ratingResults: OrganizationRatingGet[] = await responseRatings.json();
        
        const ratingsMap = Object.fromEntries(
          ratingResults.map((rating) => [
            rating.applicationUuid,
            { rating: rating.rating, rationale: rating.rationale }
          ])
        );
        setRatings(ratingsMap);
  
        // Fetch bookmarks
        const bookmarks = await fetch(
          `${Settings.API_URL}/organizations/bookmarks?interviewId=${interviewId}`,
          {
            method: 'GET',
            headers: { Authorization: `Bearer ${token}` },
          }
        );
        const bookmarksResult = await bookmarks.json();
        const bookmarksMap = Object.fromEntries(
          bookmarksResult.map((bookmark: any) => [bookmark.uuid, bookmark.bookmarked_by])
        );
        setBookmarksMap(bookmarksMap);
  
      } catch (error) {
        console.error('Error:', error);
      } finally {
        setIsLoadingSummaries(false);
      }
    };
  
    if (interviewId) {
      getSummaries();
    }
  }, [getAccessTokenSilently, interviewId]);
  

  const search = async (e: React.FormEvent) => {
    e.preventDefault();
    const inputValue = queryRef.current?.value;

    const token = await getAccessTokenSilently();
    setQueryAnswer({ answer: '' });

    const body = JSON.stringify({
      q: inputValue,
      interviewId: interviewId,
      authToken: token
    });

    try {
      setIsLoadingQueryAnswer(true);
      const response = await fetch(`${Settings.API_URL}/organizations/applications/search`, {
        method: 'POST',
        headers: {
          'Content-Type': 'text/plain'
        },
        body: body,
      });

      if (!response.ok || !response.body) {
        throw new Error('Network response was not ok');
      }

      const reader = response.body.getReader();
      const decoder = new TextDecoder('utf-8');

      let fullText = '';
      while (true) {
        const { value, done } = await reader.read();
        if (done) break;
        const chunk = decoder.decode(value, { stream: true });
        fullText += chunk;
        console.log(chunk)
        setQueryAnswer(prev => ({
          answer: prev?.answer + chunk
        }));
      }
    } catch (error) {
      console.error('Error during search:', error);
    } finally {
      setIsLoadingQueryAnswer(false);
    }
  };

  const handleShortlist = async (applicationUuid: string) => {
    const token = await getAccessTokenSilently();
    const body = { applicationUuid };
    const response = await fetch(`${Settings.API_URL}/organizations/bookmarks`, {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(body),
    });

    if (!response.ok) {
      console.error('Failed to update bookmark', await response.json());
      return;
    }

    const updatedResult = await response.json();
    if (updatedResult.bookmarked_by !== undefined) {
      setBookmarksMap((prev) => ({
        ...prev,
        [updatedResult.uuid]: updatedResult.bookmarked_by,
      }));
    }
  };

  return (
    <div className="flex flex-col">

      <div className="bg-white border border-gray-200 rounded-xl shadow-sm overflow-hidden dark:bg-neutral-800 dark:border-neutral-700 mb-4">
        <div className="px-6 py-4 grid gap-3 border-b border-gray-200 dark:border-neutral-700">
          <h2 className="text-xl font-semibold text-gray-800 dark:text-neutral-200">
            Search
          </h2>
          <div className="flex">
            <form onSubmit={search} className="flex rounded-lg shadow-sm">
              <input
                type="text"
                placeholder="Keyword search"
                ref={queryRef}
                className="py-2 px-4 min-w-[340px] block w-full border-gray-200 shadow-sm rounded-s-lg text-sm focus:border-blue-500 focus:ring-blue-500 disabled:pointer-events-none dark:bg-neutral-900 dark:border-neutral-700 dark:placeholder-neutral-500 dark:focus:ring-neutral-600 text-gray-500 dark:text-neutral-400"
              />
              <button
                type="submit"
                className="w-[2.875rem] shrink-0 inline-flex justify-center items-center gap-x-2 text-sm font-semibold rounded-e-md border border-transparent bg-blue-600 text-white"
              >
                {isLoadingQueryAnswer ? (
                  <div className="animate-spin inline-block size-6 border-[3px] border-current border-t-transparent text-white rounded-full"></div>
                ) : (
                  <Search className="w-4 h-4 shrink-0" />
                )}
              </button>
            </form>
          </div>
          <p className="text-sm mt-2 text-gray-500 dark:text-neutral-400 max-w-[620px]">
            {queryAnswer && queryAnswer.answer && (
              <span>{queryAnswer.answer}</span>
            )}
          </p>
        </div>
      </div>


      <div className="bg-white border border-gray-200 rounded-xl shadow-sm overflow-hidden dark:bg-neutral-800 dark:border-neutral-700">
        <div className="px-6 py-4 grid gap-3 md:flex md:justify-between md:items-center border-b border-gray-200 dark:border-neutral-700">
          <div>
            <h2 className="text-xl font-semibold text-gray-800 dark:text-neutral-200">
              <div className="flex items-center gap-2">
                <h2 className="text-xl font-semibold">
                  Applications for {summaries && summaries.length > 0 && (
                    <span>{summaries[0].interviewTitle}</span>
                  )}
                </h2>
                {isLoadingSummaries && (
                  <div
                    className="ml-2 animate-spin inline-block size-4 border-[3px] border-current border-t-transparent text-blue-600 rounded-full dark:text-blue-500"
                    role="status"
                    aria-label="loading"
                  ></div>
                )}
              </div>
            </h2>
          </div>
        </div>

        <div className="overflow-auto max-h-[600px]">
          <table className="min-w-full divide-y divide-gray-200 dark:divide-neutral-700">
            <thead className="z-50 sticky top-0 bg-gray-50 dark:bg-neutral-800">
              <tr>
                <th className="w-1/4 px-6 py-3 text-start">
                  <div className="sticky top-0 left-0 z-50 bg-gray-50 dark:bg-neutral-800">
                    <span className="text-xs font-semibold tracking-wide text-gray-800 dark:text-neutral-200">
                      <input
                        type="text"
                        className="p-2 min-w-[120px] block w-full border-gray-200 shadow-sm rounded-lg text-xs focus:border-blue-500 focus:ring-blue-500 disabled:pointer-events-none dark:bg-neutral-900 dark:border-neutral-700 dark:placeholder-neutral-500 dark:focus:ring-neutral-600 text-gray-500 dark:text-neutral-400" placeholder="Candidate name"
                        value={candidateFilter}
                        onChange={(e) => setCandidateFilter(e.target.value)}
                      />
                    </span>
                  </div>
                </th>
                <th className="w-1/4 px-6 py-3 text-start"><span className="text-xs font-semibold tracking-wide text-gray-800 dark:text-neutral-200">Executive summary</span></th>
                <th className="w-1/4 px-6 py-3 text-start"><span className="text-xs font-semibold tracking-wide text-gray-800 dark:text-neutral-200">Key skills</span></th>
                <th className="w-1/4 px-6 py-3 text-start"><span className="text-xs font-semibold tracking-wide text-gray-800 dark:text-neutral-200">Strengths</span></th>
                <th
                  className="w-1/4 px-6 py-3 text-start cursor-pointer"
                  onClick={() => requestSort('rating')}
                >
                  <div className="flex items-center">
                    <span className="text-xs font-semibold tracking-wide text-gray-800 dark:text-neutral-200">
                      Rating
                    </span>
                    {sortConfig.key === 'rating' && (
                      sortConfig.direction === 'ascending' ? (
                        <ArrowUp className="w-4 h-4 ml-1" stroke="#444" />
                      ) : (
                        <ArrowDown className="w-4 h-4 ml-1" stroke="#444" />
                      )
                    )}
                  </div>
                </th>
                <th className="w-1/4 px-6 py-3 text-start"></th>
              </tr>
            </thead>
            <tbody className="divide-y divide-gray-200 dark:divide-neutral-700">
              {sortedSummaries.map((summary) => {
                const ratingData = ratings[summary.applicationUuid];
                return (
                  <tr key={summary.applicationUuid}>
                    <td className="w-1/4 px-6 py-3 text-start align-top">
                      <div className="sticky left-0 z-40 dark:bg-neutral-800">
                        <NavLink
                          className="cursor-pointer text-sm flex items-center"
                          to={`/organization/dashboard/applications/${summary.applicationUuid}`}>
                          {summary.candidateName}
                        </NavLink>
                        <br />
                        <a className="cursor-pointer" target="_blank" href={summary.linkedInUrl ? summary.linkedInUrl : `https://www.linkedin.com/search/results/all/?keywords=$${summary.candidateName}`}>
                          <LinkedInLogo className="h-4 w-4" />
                        </a>
                      </div>
                    </td>
                    <td className="w-1/4 px-6 py-3 text-start align-top">
                      <span className="cursor-pointer text-sm max-w-40 tooltip-container-badge inline-block text-gray-800 dark:text-neutral-200">
                        <Tooltip
                          className="max-w-[200px] z-100"
                          id="tooltip-interview-summary"
                          place="right" />
                        {summary.interviewSummary?.substring(0, 50)}..
                        <Info
                          className="w-4 h-4 tooltip text-gray-400" data-tooltip-id="tooltip-interview-summary" data-tooltip-content={summary.interviewSummary} />
                      </span>
                    </td>
                    <td className="w-1/4 px-6 py-3 text-start align-top">
                      {summary.keySkills.map((skill, index) => (
                        <span
                          key={index}
                          title={skill}
                          className="cursor-pointer max-w-40 tooltip-container-badge truncate whitespace-nowrap inline-block items-center gap-x-1.5 py-1.5 px-3 rounded-full text-xs font-medium bg-blue-100 text-blue-800 dark:bg-blue-800/30 dark:text-blue-500 mb-2"
                          data-tooltip-id="tooltip-skill" data-tooltip-content={skill}
                        >
                          {capitalizeFirstWord(skill)}
                          <Tooltip id="tooltip-skill" style={{ zIndex: 99 }} />
                        </span>
                      ))}
                    </td>
                    <td className="w-1/4 px-6 py-3 text-start align-top">
                      {summary.strengths.map((strength, index) => (
                        <span
                          key={index}
                          title={strength}
                          className="cursor-pointer max-w-40 tooltip-container-badge truncate whitespace-nowrap inline-block items-center gap-x-1.5 py-1.5 px-3 rounded-full text-xs font-medium bg-teal-100 text-teal-800 dark:bg-teal-800/30 dark:text-teal-500 mb-2"
                          data-tooltip-id="tooltip-strength" data-tooltip-content={strength}
                        >
                          {capitalizeFirstWord(strength)}
                          <Tooltip id="tooltip-strength" style={{ zIndex: 99 }} />
                        </span>
                      ))}
                    </td>
                    <td className="w-1/4 px-6 py-3 text-start align-top">
                      {ratingData !== undefined ? (
                        <span
                          className="cursor-pointer block text-sm text-gray-800 dark:text-neutral-200 flex items-center"
                          title={ratingData.rationale}>
                          {ratingData.rating}&nbsp;
                          <Info className="w-4 h-4 tooltip text-gray-400" data-tooltip-id="tooltip-rating-rationale" data-tooltip-content={ratingData.rationale} />
                          <Tooltip className="max-w-[200px]" id="tooltip-rating-rationale" style={{ zIndex: 99 }} />
                        </span>
                      ) : (
                        <div
                          className="ml-2 animate-spin inline-block size-4 border-[3px] border-current border-t-transparent text-blue-600 rounded-full dark:text-blue-500"
                          role="status"
                          aria-label="loading"
                        ></div>
                      )}
                    </td>
                    <td className="w-1/4 px-6 py-3 text-start align-top">
                      <a
                        onClick={() => handleShortlist(summary.applicationUuid)}
                        className="text-sm flex items-center cursor-pointer"
                      >
                        <Star
                          className={`w-4 h-4 mr-1 ${bookmarksMap[summary.applicationUuid] ? 'unshortlist' : 'shortlist'}`}
                        />
                        {bookmarksMap[summary.applicationUuid] ? "Unshortlist" : "Shortlist"}
                      </a>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default ViewApplications;
