const SETTINGS_PROD = {
  WEB_URL: "https://www.worklens.io",
  API_URL: "https://www.worklens.io/api",
  CLIENT_ID_LINKEDIN_AUTH: "77yjwq40wk7l1c",
  CLIENT_ID_LINKEDIN_THIRDPARTY: "77t4w3qz1e20zo",
  CLIENT_ID_AUTH0: "FYS8ddWkYpEB4NHntX7eFuVbmDdLg286"
};

const SETTINGS_DEV = {
  WEB_URL: "http://localhost:5173",
  API_URL: "http://localhost:3000/api",
  CLIENT_ID_LINKEDIN_AUTH: "77yjwq40wk7l1c",
  CLIENT_ID_LINKEDIN_THIRDPARTY: "77t4w3qz1e20zo",
  CLIENT_ID_AUTH0: "B1PhxPQscGVpTJRM1PYQj6jdLV2o7CiL"
};


const Settings = import.meta.env.PROD ? SETTINGS_PROD : SETTINGS_DEV;

//TOOD: fix this, only used locally because Vercel caches some paths and returns stale headers
export const t = import.meta.env.PROD ? Date.now() : '';

export default Settings;