import React, { useEffect, useRef, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import Settings, { t } from './Settings';
import LinkedInLogo from "./assets/linkedin-logo.svg?react";
import { initiateLinkedInAuth, logFatalSentryError, upperCased } from './util';
import { useAuth } from './AuthContext';
import { QuestionAnswer, InterviewGET } from './types';
import Header from './components/Header';
import Footer from './components/Footer';
import { format } from 'date-fns';
import { Bot, ChevronRight, Send } from 'lucide-react';

export enum FormSteps {
  Intro = 0,
  Interview = 1,
  Confirmation = 2
}

const InterviewPage: React.FC = () => {
  const { candidate } = useAuth();
  const { id } = useParams<{ id: string }>();

  const userAnswerRef = useRef<HTMLTextAreaElement>(null);

  const [isLoadingInterview, setIsLoadingInterview] = useState(false);
  const [isSendingAnswer, setIsSendingAnswer] = useState(false);
  const [interview, setInterview] = useState<InterviewGET | null>(null);
  const [errorMessage, setErrorMessage] = useState('');
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [userAnswer, setUserAnswer] = useState('');
  const [isFollowUp, setIsFollowUp] = useState(false);
  const [followUpQuestion, setFollowUpQuestion] = useState('');
  const [isInterviewComplete, setIsInterviewComplete] = useState(false);
  const [formStep, setFormStep] = useState<FormSteps>(FormSteps.Intro);
  const [questionsAnswers, setQuestionsAnswers] = useState<QuestionAnswer[]>([]);
  const [hasAcceptedTerms, setHasAcceptedTerms] = useState(false);
  const [isSubmittingApplication, setIsSubmittingApplication] = useState(false);
  const [hasSubmittedApplication, setHasSubmittedApplication] = useState(false);
  const [timer, setTimer] = useState({ timestamp: '', hash: '' });
  const [startTime] = useState(Date.now());

  useEffect(() => {
    if (!id) return;

    const getInterview = async () => {
      setIsLoadingInterview(true);

      try {
        const response = await fetch(`${Settings.API_URL}/interviews?id=${id}&${t}`, { method: 'GET' });

        if (response.ok) {
          const responseData = await response.json();

          const interview: InterviewGET = {
            title: responseData.interview.title,
            questions: responseData.interview.questions,
            employerName: responseData.interview.employerName,
            expireAt: responseData.interview.expireAt,
            settingsLLM: responseData.interview.settingsLLM,
            versionId: responseData.interview.versionId,
          };

          setInterview(interview);
          setTimer(responseData.timer);
        } else {
          setErrorMessage(
            response.status === 410
              ? 'The interview has expired.'
              : response.status === 404
                ? 'The interview was not found.'
                : 'An error occurred while fetching the interview.'
          );
        }
      } catch (error) {
        console.error('Error fetching interview:', error);
        setErrorMessage('An error occurred while fetching the interview.');
      } finally {
        setIsLoadingInterview(false);
      }
    };

    getInterview();
  }, [id]); // Only depend on id

  const handleAnswerSubmit = async () => {
    if (userAnswer.trim() !== '' && interview) {
      setIsSendingAnswer(true);
      const questionId = interview.questions[currentQuestionIndex].id;
      const currentQuestionText = isFollowUp ? followUpQuestion : interview.questions[currentQuestionIndex].text;

      // Prepare the payload
      const answerPayload = {
        questionId: questionId,
        question: currentQuestionText,
        answer: userAnswer.trim(),
        settingsLLM: interview.settingsLLM,
      };

      try {
        if (!interview.settingsLLM.askFollowUpQuestions) {
          // Handle logic locally without server call
          const newQuestionAnswer: QuestionAnswer = {
            questionId: questionId,
            answer: userAnswer.trim(),
            followUps: [],
          };

          setQuestionsAnswers([...questionsAnswers, newQuestionAnswer]);
          setUserAnswer('');

          if (currentQuestionIndex < interview.questions.length - 1) {
            setCurrentQuestionIndex((prevIndex) => prevIndex + 1);
          } else {
            setIsInterviewComplete(true);
            setFormStep(FormSteps.Confirmation);
            window.scrollTo({
              top: 0,
              behavior: 'smooth',
            });
          }
        } else {
          // Make the server call when follow-up questions are enabled
          const answerResponse = await fetch(`${Settings.API_URL}/interviews`, {
            method: 'PUT',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(answerPayload),
          });

          const answer = await answerResponse.json();
          let parsedEvaluation;

          try {
            parsedEvaluation = JSON.parse(answer.evaluationMessage);
          } catch (error) {
            console.error('Failed to parse evaluationMessage:', error);
            parsedEvaluation = { isAdequate: false, followUpQuestion: '' };
          }

          if (isFollowUp) {
            setQuestionsAnswers((prevQuestionsAnswers) => {
              return prevQuestionsAnswers.map((qaItem, index) => {
                if (index === prevQuestionsAnswers.length - 1) {
                  return {
                    ...qaItem,
                    followUps: [
                      ...qaItem.followUps,
                      {
                        id: qaItem.followUps.length,
                        question: followUpQuestion,
                        answer: userAnswer.trim(),
                      },
                    ],
                  };
                }
                return qaItem;
              });
            });

            setIsFollowUp(false);
            setFollowUpQuestion('');
          } else {
            const newQuestionAnswer: QuestionAnswer = {
              questionId: questionId,
              answer: userAnswer.trim(),
              followUps: [],
            };

            setQuestionsAnswers([...questionsAnswers, newQuestionAnswer]);
          }

          setUserAnswer('');

          if (
            parsedEvaluation.isAdequate ||
            (!isFollowUp && !interview.settingsLLM.askFollowUpQuestions)
          ) {
            if (currentQuestionIndex < interview.questions.length - 1) {
              setCurrentQuestionIndex((prevIndex) => prevIndex + 1);
            } else {
              setIsInterviewComplete(true);
              setFormStep(FormSteps.Confirmation);
              window.scrollTo({
                top: 0,
                behavior: 'smooth',
              });
            }
          } else if (interview.settingsLLM.askFollowUpQuestions) {
            setIsFollowUp(true);
            setFollowUpQuestion(parsedEvaluation.followUpQuestion);
          }
        }
      } catch (error) {
        console.error('Failed to submit the answer:', error);
        setErrorMessage('Failed to submit the answer.');
        logFatalSentryError('Submit application error', error);
      } finally {
        setIsSendingAnswer(false);
        userAnswerRef.current?.focus();
      }
    }
  };

  const handleApplicationSubmit = async () => {
    setIsSubmittingApplication(true);

    const body = JSON.stringify({
      application: {
        questionsAnswers: questionsAnswers,
        interviewId: id,
        hasAcceptedTerms: hasAcceptedTerms,
        interviewVersionId: interview?.versionId,
      },
      timer: {
        hash: timer.hash,
        timestamp: timer.timestamp,
        elapsedTime: Date.now() - startTime,
      },
    });

    try {
      await fetch(`${Settings.API_URL}/applications`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: body,
        credentials: 'include',
      });

      setHasSubmittedApplication(true);
      setIsSubmittingApplication(false);
    } catch (error) {
      console.error('Error inserting application:', error);
    }
  };

  return (
    <main className="flex-grow">
      <Header />

      <div className="relative overflow-hidden px-4 sm:px-6 lg:px-8 py-4 sm:py-8 flex justify-center items-center">
        <div className="max-w-[40rem] bg-white border rounded-xl dark:bg-neutral-900 dark:border-neutral-700 dark:shadow-neutral-700/70 p-4 md:p-6">
          {isLoadingInterview && (
            <div
              className="animate-spin inline-block size-6 border-[3px] border-current border-t-transparent text-blue-600 rounded-full dark:text-blue-500"
              role="status"
              aria-label="loading"
            >
              <span className="sr-only">Loading...</span>
            </div>
          )}

          {!isLoadingInterview && interview && (
            <div>
              {/* Form Steps Navigation */}
              <div className="p-2 grid sm:grid-cols-3 gap-12 mb-8">
                {/* Step 1 */}
                <div className="flex items-center">
                  <div className="flex-shrink-0 mr-4">
                    <div className="relative flex justify-center items-center size-8 bg-white rounded-xl dark:bg-neutral-900">
                      <div className="flex items-center whitespace-nowrap">
                        <span
                          className={`${formStep === FormSteps.Intro ? 'bg-[#2563eb]' : 'bg-gray-700'
                            } w-8 h-8 border dark:border-indigo-200 rounded-full flex justify-center items-center text-sm text-white`}
                        >
                          1
                        </span>
                      </div>
                    </div>
                  </div>
                  <div>
                    <h3 className="font-semibold text-gray-800 dark:text-white">Introduction</h3>
                  </div>
                </div>
                {/* Step 2 */}
                <div className="flex items-center">
                  <div className="flex-shrink-0 mr-4">
                    <div className="relative flex justify-center items-center size-8 bg-white rounded-xl dark:bg-neutral-900">
                      <div className="flex items-center whitespace-nowrap">
                        <span
                          className={`${formStep === FormSteps.Interview ? 'bg-[#2563eb]' : 'bg-gray-700'
                            } w-8 h-8 border dark:border-indigo-200 rounded-full flex justify-center items-center text-sm text-white`}
                        >
                          2
                        </span>
                      </div>
                    </div>
                  </div>
                  <div>
                    <h3 className="font-semibold text-gray-800 dark:text-white">Interview</h3>
                  </div>
                </div>
                {/* Step 3 */}
                <div className="flex items-center">
                  <div className="flex-shrink-0 mr-4">
                    <div className="relative flex justify-center items-center size-8 bg-white rounded-xl dark:bg-neutral-900">
                      <div className="flex items-center whitespace-nowrap">
                        <span
                          className={`${formStep === FormSteps.Confirmation ? 'bg-[#2563eb]' : 'bg-gray-700'
                            } w-8 h-8 border dark:border-indigo-200 rounded-full flex justify-center items-center text-sm text-white`}
                        >
                          3
                        </span>
                      </div>
                    </div>
                  </div>
                  <div>
                    <h3 className="font-semibold text-gray-800 dark:text-white">Confirmation</h3>
                  </div>
                </div>
              </div>

              <div className="max-w-[40rem] mx-auto mt-5">
                {formStep === FormSteps.Intro && (
                  <div className="container">
                    <h2 className="text-2xl font-bold mb-1 text-gray-800 dark:text-white">
                      Interview for {upperCased(interview?.title)} at{' '}
                      {upperCased(interview?.employerName)}
                    </h2>
                    <p className="mb-6 mt-2 text-gray-500 dark:text-neutral-400">
                      {interview.expireAt && (
                        <>Expires at {format(new Date(interview.expireAt), 'yyyy-MM-dd')}</>
                      )}
                    </p>
                    <p className="mb-8 mt-2 text-gray-500 dark:text-neutral-400">
                      To help us get to know you better, we'd love for you to complete our digital screening.
                      This will help us understand your fit for the role.
                      <br /><br />
                      There’s no time limit, so take as much time as you need to provide thoughtful responses.
                      After you complete the interview, any follow-up communication will be sent directly to your email.
                      <br /><br />
                      <strong>Please note</strong>, this digital screening tool is currently in beta and if you encounter any issues or have feedback,
                      feel free to reach out to <a href="mailto:contact@worklens.io">contact@worklens.io</a>
                      <br /><br />
                      Good luck, and we look forward to learning
                      more about you!
                      <br />
                    </p>

                    {!candidate ? (
                      <>
                        <span className="text-gray-500 dark:text-neutral-400">
                          Please sign in with LinkedIn to start your interview.
                        </span>
                        <div className="max-w-sm text-start mt-4">
                          <button 
                            onClick={() => initiateLinkedInAuth(window.location.href)}
                            type="button" className="py-3 px-4 inline-flex items-center gap-x-2 text-sm font-medium rounded-lg border border-transparent bg-blue-600 text-white hover:bg-blue-700 focus:outline-none focus:bg-blue-700 disabled:opacity-50 disabled:pointer-events-none"
                            >
                            <span className="w-6 h-6">
                              <LinkedInLogo />
                            </span>
                            <span className="ml-2 text-white">Sign in with LinkedIn</span>
                          </button>
                        </div>
                      </>
                    ) : (
                      <>
                        <button
                          onClick={() => setFormStep(FormSteps.Interview)}
                          className="py-3 px-4 inline-flex items-center gap-x-2 text-sm font-medium rounded-lg border border-transparent bg-blue-600 text-white hover:bg-blue-700 focus:outline-none focus:bg-blue-700 disabled:opacity-50 disabled:pointer-events-none"
                        >
                          Get started
                          <ChevronRight />
                        </button>
                      </>
                    )}
                  </div>
                )}

                {formStep === FormSteps.Interview && candidate && (
                  <div className="container">
                    <ul className="space-y-5">
                      {questionsAnswers.map((qaItem, index) => (
                        <React.Fragment key={index}>
                          {/* Main Question */}
                          <li className="max-w-lg flex gap-x-2 sm:gap-x-4 me-11">
                            <div className="inline-block size-9 rounded-full">
                              <Bot className="h-10 w-10 text-black dark:text-white" />
                            </div>
                            <div className="bg-white border border-gray-200 rounded-2xl p-4 space-y-3 dark:bg-neutral-900 dark:border-neutral-700">
                              <p className="mb-1.5 text-sm text-gray-800 dark:text-white">
                                {
                                  interview.questions.find(
                                    (q) => q.id === qaItem.questionId
                                  )?.text
                                }
                              </p>
                            </div>
                          </li>
                          {/* Main Answer */}
                          <li className="flex ms-auto gap-x-2 sm:gap-x-4">
                            <div className="grow text-end space-y-3">
                              <div className="inline-block bg-blue-600 rounded-2xl p-4 shadow-sm">
                                <p className="mb-1.5 text-sm text-white">{qaItem.answer}</p>
                              </div>
                            </div>
                            <span className="shrink-0 inline-flex items-center justify-center size-[38px] rounded-full bg-gray-600">
                              <span className="text-sm font-medium text-white leading-none">
                                You
                              </span>
                            </span>
                          </li>
                          {/* Follow-Ups */}
                          {qaItem.followUps.map((followUp, fIndex) => (
                            <React.Fragment key={fIndex}>
                              {/* Follow-Up Question */}
                              <li className="max-w-lg flex gap-x-2 sm:gap-x-4 me-11">
                                <div className="inline-block size-9 rounded-full">
                                  <Bot className="h-10 w-10 text-black dark:text-white" />
                                </div>
                                <div className="bg-white border border-gray-200 rounded-2xl p-4 space-y-3 dark:bg-neutral-900 dark:border-neutral-700">
                                  <p className="mb-1.5 text-sm text-gray-800 dark:text-white">
                                    {followUp.question}
                                  </p>
                                </div>
                              </li>
                              {/* Follow-Up Answer */}
                              <li className="flex ms-auto gap-x-2 sm:gap-x-4">
                                <div className="grow text-end space-y-3">
                                  <div className="inline-block bg-blue-600 rounded-2xl p-4 shadow-sm">
                                    <p className="mb-1.5 text-sm text-white">{followUp.answer}</p>
                                  </div>
                                </div>
                                <span className="shrink-0 inline-flex items-center justify-center size-[38px] rounded-full bg-gray-600">
                                  <span className="text-sm font-medium text-white leading-none">
                                    You
                                  </span>
                                </span>
                              </li>
                            </React.Fragment>
                          ))}
                        </React.Fragment>
                      ))}

                      {/* Render current question or follow-up */}
                      {!isInterviewComplete && (
                        <>
                          {isFollowUp ? (
                            <>
                              {/* Follow-Up Question */}
                              <li className="max-w-lg flex gap-x-2 sm:gap-x-4 me-11">
                                <div className="inline-block size-9 rounded-full">
                                  <Bot className="h-10 w-10 text-black dark:text-white" />
                                </div>
                                <div className="bg-white border border-gray-200 rounded-2xl p-4 space-y-3 dark:bg-neutral-900 dark:border-neutral-700">
                                  <p className="mb-1.5 text-sm text-gray-800 dark:text-white">
                                    {followUpQuestion}
                                  </p>
                                </div>
                              </li>
                            </>
                          ) : (
                            interview?.questions[currentQuestionIndex] && (
                              <>
                                {/* Main Question */}
                                <li className="max-w-lg flex gap-x-2 sm:gap-x-4 me-11">
                                  <div className="inline-block size-9 rounded-full">
                                    <Bot className="h-10 w-10 text-black dark:text-white" />
                                  </div>
                                  <div className="bg-white border border-gray-200 rounded-2xl p-4 space-y-3 dark:bg-neutral-900 dark:border-neutral-700">
                                    <p className="mb-1.5 text-sm text-gray-800 dark:text-white">
                                      {interview.questions[currentQuestionIndex].text}
                                    </p>
                                  </div>
                                </li>
                              </>
                            )
                          )}

                          {/* Answer Input */}
                          <li className="relative !mt-10">
                            <textarea
                              ref={userAnswerRef}
                              className="p-4 pb-20 block w-full bg-gray-100 border-gray-200 rounded-lg text-sm focus:border-blue-500 focus:ring-blue-500 dark:bg-neutral-800 dark:border-neutral-700 text-black dark:text-white dark:placeholder-neutral-500 dark:focus:ring-neutral-600"
                              value={userAnswer}
                              onChange={(e) => setUserAnswer(e.target.value)}
                              placeholder="Your answer"
                            />
                            <div className="absolute bottom-0 px-4 mb-2 inset-x-0 rounded-b-lg bg-gray-100 bg-opacity-0 dark:bg-neutral-800 dark:bg-opacity-0">
                              <div className="flex justify-between items-center">
                                <div className="flex items-center gap-x-1">
                                  <span className="text-xs text-neutral-500">Question {currentQuestionIndex + 1}/{interview.questions.length}</span>
                                </div>
                                <div className="flex items-center gap-x-1">
                                  <button
                                    type="button"
                                    className="inline-flex shrink-0 justify-center items-center size-8 rounded-lg text-white bg-blue-600 hover:bg-blue-500"
                                    onClick={handleAnswerSubmit}
                                  >
                                    {isSendingAnswer ? (
                                      <div className="animate-spin inline-block size-6 border-[3px] border-current border-t-transparent text-white rounded-full"></div>
                                    ) : (
                                      <Send className="w-4 h-4" />
                                    )}
                                  </button>
                                </div>
                              </div>
                            </div>
                          </li>
                        </>
                      )}
                    </ul>
                  </div>
                )}

                {formStep === FormSteps.Confirmation && candidate && (
                  <>
                    {!hasSubmittedApplication && (
                      <>
                        <h2 className="text-2xl font-bold mb-1 text-gray-800 dark:text-white">Thank you</h2>
                        {!candidate.acceptedTermsAt && (
                          <>
                            <p className="mt-4 text-gray-600 dark:text-neutral-400 mb-8">
                              Great job! You’ve completed your interview. Just review and accept the
                              terms below to submit your responses.
                            </p>
                            <div className="flex space-x-4 mb-6">
                              <label className="inline-flex items-center space-x-2">
                                <input
                                  type="checkbox"
                                  checked={hasAcceptedTerms}
                                  onChange={() => setHasAcceptedTerms(!hasAcceptedTerms)}
                                  className="form-checkbox h-5 w-5 text-blue-600"
                                />
                                <span className="text-sm dark:text-white">
                                  Accept the&nbsp;
                                  <Link
                                    to="/agreement-candidate"
                                    className="text-blue-600 decoration-2 hover:underline focus:outline-none focus:underline font-medium dark:text-blue-500"
                                    target="_blank"
                                    onClick={() => window.scrollTo(0, 0)}
                                  >
                                    terms and conditions
                                  </Link>
                                </span>
                              </label>
                            </div>
                            <button
                              onClick={handleApplicationSubmit}
                              type="button"
                              disabled={!hasAcceptedTerms || isSubmittingApplication}
                              className="py-3 px-4 inline-flex items-center gap-x-2 text-sm font-medium rounded-lg border border-transparent bg-blue-600 text-white hover:bg-blue-700 focus:outline-none focus:bg-blue-700 disabled:opacity-50 disabled:pointer-events-none"
                            >
                              {isSubmittingApplication ? (
                                <>
                                  Submitting...
                                  <div className="animate-spin inline-block size-4 border-[3px] border-current border-t-transparent text-white rounded-full"></div>
                                </>
                              ) : (
                                <>
                                  Submit my interview
                                  <Send className="w-4 h-4" />
                                </>
                              )}
                            </button>
                          </>
                        )}

                        {(candidate.acceptedTermsAt &&
                          <>
                            <p className="mt-4 text-gray-600 dark:text-neutral-400 mb-8">
                              Great job! You’ve completed your interview. Click below to submit your responses.
                            </p>
                            <button
                              onClick={handleApplicationSubmit}
                              type="button"
                              disabled={isSubmittingApplication}
                              className="py-3 px-4 inline-flex items-center gap-x-2 text-sm font-medium rounded-lg border border-transparent bg-blue-600 text-white hover:bg-blue-700 focus:outline-none focus:bg-blue-700 disabled:opacity-50 disabled:pointer-events-none"
                            >
                              {isSubmittingApplication ? (
                                <>
                                  Submitting...
                                  <div className="animate-spin inline-block size-4 border-[3px] border-current border-t-transparent text-blue-600 rounded-full dark:text-blue-500"></div>
                                </>
                              ) : (
                                <>
                                  Submit my interview
                                  <Send className="w-4 h-4" />
                                </>
                              )}
                            </button>
                          </>
                        )}

                      </>
                    )}

                    {hasSubmittedApplication && (
                      <>
                        <h2 className="text-2xl font-bold mb-1 text-gray-800 dark:text-white">
                          Thank you for applying to {upperCased(interview?.title)} at {upperCased(interview?.employerName)}
                        </h2>
                        <p className="mt-4 text-gray-600 dark:text-neutral-400">
                          If you are selected to the next step as a candidate, you will receive an email at <span className="text-blue-600">{candidate.email}</span> within the next coming weeks.
                          <br />
                          <br />
                          Thanks for using Worklens!
                        </p>
                      </>
                    )}
                  </>
                )}

              </div>
            </div>
          )}

          {errorMessage && <div className="text-red-500">{errorMessage}</div>}
        </div>

      </div>

      <Footer />
    </main>
  );

};

export default InterviewPage;
