import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import en from './locales/en.json';
import sv from './locales/sv.json';

const getDefaultLanguage = () => {
  // Detect browser language
  const browserLanguage = navigator.language || navigator.language;
  // Match supported languages (en, sv) or fallback
  const supportedLanguages = ['en', 'sv'];
  return supportedLanguages.find(lang => browserLanguage.startsWith(lang)) || 'sv';
};

i18n.use(initReactI18next).init({
  resources: {
    en: { translation: en },
    sv: { translation: sv },
  },
  lng: localStorage.getItem('language') || getDefaultLanguage(),
  fallbackLng: 'en',
  interpolation: {
    escapeValue: false, // React already protects from XSS
  },
});

export default i18n;
