'use client';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

export default function LanguagePicker() {
  const { i18n } = useTranslation();
  const [language, setLanguage] = useState<'en' | 'sv'>(() => {
    // Get the initial language from localStorage or default to 'en'
    return (localStorage.getItem('language') as 'en' | 'sv') || 'en';
  });

  const toggleLanguage = () => {
    const newLanguage = language === 'en' ? 'sv' : 'en';
    setLanguage(newLanguage);
    i18n.changeLanguage(newLanguage);
    localStorage.setItem('language', newLanguage);
    window.location.reload();
  };

  useEffect(() => {
    i18n.changeLanguage(language); // Ensure the language is applied on component mount
  }, [language, i18n]);

  return (
    <button onClick={toggleLanguage}>
      {language === 'sv' ? (
        <span className="text-gray-800 dark:text-white">
          <span className="mr-2 text-lg">🇬🇧</span>
        </span>
      ) : (
        <span className="text-gray-800 dark:text-white">
          <span className="mr-2 text-lg">🇸🇪</span>
        </span>
      )}
      <span className="sr-only text-gray-800 dark:text-white">Toggle language</span>
    </button>
  );
}
