import React from 'react';
import AnimationWand from "./assets/animation-wand.svg?react";
import { ChartColumnDecreasing, MessagesSquare, PersonStanding, Play } from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import Header from './components/Header';
import Footer from './components/Footer';
import ChatDemo from './components/ChatDemo';
import { Tooltip } from 'react-tooltip';
import SearchDemo from './components/SearchDemo';
import LeadGenForm from './components/LeadGen';
import { useTranslation } from 'react-i18next';

const LandingPage: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <main className="flex-grow">
      <div aria-hidden="true" className="!w-full flex absolute -top-96 start-1/2 transform -translate-x-1/2">
        <div className="bg-gradient-to-r from-violet-300/50 to-purple-100 blur-3xl w-[25rem] h-[44rem] rotate-[-60deg] transform -translate-x-[10rem] dark:from-violet-900/50 dark:to-purple-900" />
        <div className="bg-gradient-to-tl from-blue-50 via-blue-100 to-blue-50 blur-3xl w-[90rem] h-[50rem] rounded-fulls origin-top-left -rotate-12 -translate-x-[15rem] dark:from-indigo-900/70 dark:via-indigo-900/70 dark:to-blue-900/70" />
      </div>

      <Header />

      <div className="relative">
        <div className="max-w-[1240px] min-h-[600px] px-4 py-10 sm:px-6 lg:px-8 lg:py-14 mx-auto">
          <div className="grid items-start md:grid-cols-2 gap-8 lg:gap-12">
            <div className="mt-4 max-w-2xl">
              <h1 className="text-3xl font-bold text-gray-800 sm:text-4xl text-gray-800 dark:text-white">
                {t('title')}<br />
                <span className="whitespace-pre-wrap text-transparent bg-clip-text bg-gradient-to-r from-purple-600 to-blue-500 text-3xl md:text-4xl">
                  {t('subtitle')}
                </span>
              </h1>
              <p className="text-gray-600 text-lg dark:text-white text-neutral-700 my-2">
                {t('main_description')}
              </p>
              <span
                data-tooltip-id="tooltip-gdpr"
                data-tooltip-content={t('tooltip_gdpr')}
                className="z-100 inline-flex items-center gap-x-1.5 py-1 px-3 rounded-md text-xs font-medium border border-gray-200 bg-white text-gray-800 shadow-sm dark:bg-neutral-900 dark:border-neutral-700 dark:text-white mb-4"
              >
                {t('gdpr_compliant')}
                <Tooltip
                  id="tooltip-gdpr"
                  className="max-w-[200px]"
                />
              </span><br />
              <button
                onClick={() => navigate("/create-organization")}
                className="relative inline-flex items-center justify-center p-0.5 mb-2 me-2 overflow-hidden text-sm font-medium text-gray-900 rounded-lg group bg-gradient-to-br from-purple-600 to-blue-500 group-hover:from-purple-600 group-hover:to-blue-500 hover:text-white dark:text-white"
              >
                <span className="relative px-5 py-1 transition-all ease-in duration-75 bg-white rounded-md bg-opacity-0 flex items-center">
                  <span className="mr-2 text-lg text-white">{t('cta_get_started_label')}</span>
                  <div className="analyze">
                    <AnimationWand />
                  </div>
                </span>
              </button>
            </div>

            <ChatDemo />
          </div>
        </div>
      </div>

      <div className="relative">
        <div className="mx-8">
          <div className="max-w-[65rem] mx-auto mb-12">
            <div className="dark:text-neutral-200 py-3 flex items-center text-sm text-gray-800 gap-x-1.5 after:flex-1 after:border-t after:border-gray-200 after:ms-6 text-gray-400 dark:after:border-neutral-700 mt-8 mb-8">
              <span className="font-semibold bg-clip-text bg-gradient-to-l from-blue-600 to-violet-500 text-transparent dark:from-blue-400 dark:to-violet-400">
                1000+
              </span>
              {t('section_trust')}
            </div>
            <div className="mx-auto mt-16 max-w-2xl sm:mt-20 lg:mt-24 lg:max-w-4xl">
              <dl className="grid max-w-xl grid-cols-1 gap-x-8 gap-y-10 lg:max-w-none lg:grid-cols-2 lg:gap-y-16">
                <div className="relative pl-16">
                  <dt className="text-base/7 font-semibold text-gray-800 dark:text-white">
                    <div className="absolute left-0 top-0 flex size-10 items-center justify-center rounded-lg bg-indigo-600">
                      <MessagesSquare className="w-6 h-6 text-white" />
                    </div>
                    {t('feature_screening_title')}
                  </dt>
                  <dd className="mt-2 text-base/7 text-gray-600">
                    {t('feature_screening_description')}
                  </dd>
                </div>
                <div className="relative pl-16">
                  <dt className="text-base/7 font-semibold text-gray-800 dark:text-white">
                    <div className="absolute left-0 top-0 flex size-10 items-center justify-center rounded-lg bg-indigo-600">
                      <PersonStanding className="w-6 h-6 text-white" />
                    </div>
                    {t('feature_human_title')}
                  </dt>
                  <dd className="mt-2 text-base/7 text-gray-600">
                    {t('feature_human_description')}
                  </dd>
                </div>
                <div className="relative pl-16">
                  <dt className="text-base/7 font-semibold text-gray-800 dark:text-white">
                    <div className="absolute left-0 top-0 flex size-10 items-center justify-center rounded-lg bg-indigo-600">
                      <ChartColumnDecreasing className="w-6 h-6 text-white" />
                    </div>
                    {t('feature_analysis_title')}
                  </dt>
                  <dd className="mt-2 text-base/7 text-gray-600">
                    {t('feature_analysis_description')}
                  </dd>
                </div>
                <div className="relative pl-16">
                  <dt className="text-base/7 font-semibold text-gray-800 dark:text-white">
                    <div className="absolute left-0 top-0 flex size-10 items-center justify-center rounded-lg bg-indigo-600">
                      <Play className="w-6 h-6 text-white" />
                    </div>
                    {t('feature_plug_play_title')}
                  </dt>
                  <dd className="mt-2 text-base/7 text-gray-600">
                    {t('feature_plug_play_description')}
                  </dd>
                </div>
              </dl>
            </div>
          </div>

          <div className="overflow-hidden mt-[6rem] max-w-[65rem] mx-auto mb-12 text-left md:order-1 relative focus:outline-none p-6 flex flex-col justify-center md:min-h-[230px] rounded-xl before:absolute before:inset-0 before:z-10 before:border before:border-gray-200 before:rounded-xl before:transition before:hover:border-2 before:hover:border-blue-600 before:focus:border-2 before:focus:border-blue-600 before:hover:shadow-lg dark:before:border-neutral-800 dark:before:hover:border-blue-500 dark:before:focus:border-blue-500 dark:rounded-xl">
            <h2 className="text-center text-2xl md:text-3xl font-bold text-gray-800 dark:text-neutral-200 mb-2">{t('section_search_title')}</h2>
            <div className="p-2 mb-4">
              <SearchDemo />
            </div>
            <p className="text-gray-600 dark:text-white text-center mx-4 md:mx-12">{t('section_search_description')}</p>
          </div>
        </div>
      </div>

      <div className="relative">
        <div className="mx-8">
          <div className="max-w-[65rem] mx-auto mb-12">
            <LeadGenForm />
          </div>
        </div>
      </div>

      <Footer />
    </main>
  );
};

export default LandingPage;
