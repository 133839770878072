export type Question = {
  id: number;
  text: string;
};

export type QuestionAnswer = {
  questionId: number;
  questionText?: string;
  answer: string;
  followUps: { id: number, question: string, answer: string}[]
}

export type InterviewGET = {
  title: string;
  questions: Question[];
  expireAt: string;
  employerName: string;
  settingsLLM: InterviewSettingsLLM;
  versionId?: number;
}

export type InterviewState = {
  externalUserId: string;
  state: InterviewStateType;
  created: string;
}

export enum InterviewStateType {
  Draft = 0,
  Published = 1,
  Unpublished = 2,
  Expired = 3
}

export type OrganizationInterviewGet = {
  id: number;
  title: string;
  employerName: string;
  jobAd: string;
  expireAt: string | null;
  state: InterviewState[];
  created: string;
  settingsLLM: InterviewSettingsLLM;
  questions?: Question[];
  applications?: OrganizationApplicationGet[];
}

export type OrganizationApplicationGet = {
  id: number;
  uuid: string;
  interviewTitle: string;
  created: string;
  questionsAnswers?: QuestionAnswer[];
  candidate: OrganizationCandidateGet;
}

export type OrganizationCandidateGet = {
  id: number;
  firstname: string;
  lastname: string;
  email: string;
}

export type OrganizationSummaryGet = {
  applicationUuid: string;
  candidateId: number;
  candidateName: string;
  interviewSummary: string;
  backgroundSummary: {
    location: string;
    languages: string[];
    keysSkills: string[];
    workSummary: string;
    educationSummary: string;
  };
  linkedInUrl: string;
  interviewTitle: string;
  keySkills: string[];
  strengths: string[];
}

export type OrganizationRatingGet = {
  applicationUuid: string;
  candidateId: number;
  candidateName: string;
  rating: number;
  rationale: string;
}

export type OrganizationInterviewCreate = {
  title: string;
  jobAd: string;
  expireAt: string | null;
  employerName: string;
  stateType: InterviewStateType;
  questions: Question[];
  settingsLLM: InterviewSettingsLLM;
}

export type OrganizationInterviewUpdate = {
  id: number;
  title: string;
  jobAd: string;
  expireAt: string | null;
  stateType: InterviewStateType;
  questions: Question[];
  settingsLLM: InterviewSettingsLLM;
}

export type InterviewVersion = {
  id: number;
  interviewId: number;
  externalUserId: string;
  questions: Question[];
  created: Date;
}

export type Organization = {
  name: string;
}

export enum InterviewSettingsLLMTonality {
  Casual = 0,
  Neutral = 1,
  Formal = 2,
  Technical = 3,
  Empathetic = 4
}

export type InterviewSettingsLLM = {
  askFollowUpQuestions: boolean;
  tonality: InterviewSettingsLLMTonality
}