import React, { useEffect } from 'react';
import { Routes, Route } from 'react-router-dom';
import SideNavOrganization from './components/SideNavOrganization';
import DashboardOverview from './components/DashboardOverview';
import ProfileUser from './components/ProfileUser';
import CreateInterview from './components/CreateInterview';
import UpdateInterview from './components/UpdateInterview';
import Application from './components/Application';
import ViewApplications from './components/ViewApplications';

import { useAuth0 } from '@auth0/auth0-react';
import Settings from '../Settings';

export enum DashboardView {
  Dashboard = 'dashboard',
  Applications = 'applications',
  ProfileUser = 'profile-user',
  CreateInterview = 'create-interview',
  EditInterview = 'edit-interview',
  ViewSummaries = 'view-summaries',
  ViewApplication = 'view-application',
}

const Dashboard: React.FC = () => {
  const { getAccessTokenSilently } = useAuth0();

  useEffect(() => {
    const fetchOrganizationName = async () => {
      const cachedOrgName = localStorage.getItem('organizationName');
      if (!cachedOrgName) {
        try {
          const token = await getAccessTokenSilently();
          const response = await fetch(`${Settings.API_URL}/organizations`, {
            method: 'GET',
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });

          if (!response.ok) {
            throw new Error('Could not get organization');
          }

          const organization = await response.json();
          localStorage.setItem('organizationName', organization.name);
        } catch (error) {
          console.error('Error fetching organization:', error);
        }
      }
    };

    fetchOrganizationName();
  }, []);

  return (
    <main className="flex-grow bg-[#f9f9f9] dark:bg-transparent">
      
      <SideNavOrganization />

      <div className="w-full lg:ps-64">
        <div className="p-4 sm:p-6 space-y-4 sm:space-y-6">
          <div className="-m-1.5 overflow-x-auto">
            <div className="p-1.5 min-w-full inline-block align-middle">
              <Routes>
                <Route path="/" element={<DashboardOverview />} />
                <Route path="/profile" element={<ProfileUser />} />
                <Route path="/interviews/create" element={<CreateInterview />} />
                <Route path="/interviews/edit/:interviewId" element={<UpdateInterview />} />
                <Route path="/applications/:applicationUuid" element={<Application />} />
                <Route path="/interviews/:interviewId/applications" element={<ViewApplications />} />
              </Routes>
            </div>
          </div>
        </div>
      </div>

    </main>
  );
};

export default Dashboard;
