import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from './AuthContext';
import Header from './components/Header';
import Footer from './components/Footer';

const ProfilePageCandidate: React.FC = () => {
  const { candidate, logoutCandidate } = useAuth();
  const navigate = useNavigate();

  const handleLogout = () => {
    logoutCandidate();
    navigate('/');
  };

  if (!candidate) {
    return <div>Loading...</div>;
  }

  return (
    <main className="flex-grow">

      <Header />

      <div className="relative overflow-hidden text-left">
        <div className="max-w-[40rem] mx-auto px-4 sm:px-6 lg:px-8 py-4 sm:py-24">
          <div className="flex items-center gap-x-3">
            <div className="shrink-0">
              <img
                className="shrink-0 size-16 rounded-full"
                src={candidate.picture}
                alt="Profile"
              />
            </div>
            <div className="grow">
              <h1 className="text-lg font-medium text-gray-800 dark:text-neutral-200">
                {candidate.name}
              </h1>
            </div>
          </div>
          <div className="mt-8">
            <button
              onClick={handleLogout}
              className="py-2 px-4 bg-blue-500 text-white rounded hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50"
            >
              Logout
            </button>
          </div>
        </div>
      </div>

      <Footer />
      
    </main>
  );
};

export default ProfilePageCandidate;