import ReactDOM from 'react-dom/client';
import App from './App';
import { Auth0Provider } from '@auth0/auth0-react';
import * as Sentry from "@sentry/react";
import Settings from './Settings';
import i18n from './i18n';
import { I18nextProvider } from 'react-i18next';
import('preline')
import 'react-toastify/dist/ReactToastify.css';

Sentry.init({
  dsn: "https://d1d4207acce0f14f8304e1c6ff25f8a2@o4508319121276928.ingest.de.sentry.io/4508319124488272",
  integrations: [
    Sentry.replayIntegration(),
  ],
  replaysSessionSampleRate: 0.5, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

ReactDOM.createRoot(document.getElementById('root')!).render(
  <Auth0Provider
    domain="worklens.eu.auth0.com"
    clientId={Settings.CLIENT_ID_AUTH0}
    cacheLocation="localstorage"
    authorizationParams={{
      redirect_uri: Settings.WEB_URL + "/organization/dashboard",
      audience: "https://worklens.eu.auth0.com/api/v2/"
    }}>
    <I18nextProvider i18n={i18n}>
      <App />
    </I18nextProvider>
  </Auth0Provider >
);